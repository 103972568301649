import apisauce from 'apisauce';
import store from '../../store';
import { signout } from '../../store/app/slice';
// import packageJson from '../../../package.json';

// const IDENTIFIER = packageJson.identifier;

const instance = apisauce.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

instance.axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().app.user
      ? store.getState().app.user.token
      : '';
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  }
  //    (error) => {
  //        console.log({error})
  //         if (error.status === 401) {
  //             store.getState().app = appInitialState;
  //             store.getState().auth = authInitialState;
  //             store.getState().dataset = datasetInitialState;
  //             store.getState().package = packageInitialState;
  //             store.getState().flow = flowInitialState;
  //             store.getState().training = trainingInitialState;
  //         }
  //     }
);

instance.axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      // store.dispatch(clearDatasetAll());
      // store.dispatch(clearPackageAll());
      // store.dispatch(clearFlowAll());
      // store.dispatch(clearTrainingAll());
      // store.dispatch(clearAppAll());
      store.dispatch(signout());
      // store.dispatch(setNotificationBarOpen({open: true, type: 'warning', message: error.response.data.error.name}));
    } else {
      return Promise.reject(error);
    }
  }
);

const api = {
  get: {
    qrCode: (vm, company_id) =>
      instance.get(
        `${window.WS_URL}/admin/vms/qr-code?vm=${vm}&company_id=${company_id}`
      ),
    companyLogo: (companyName) =>
      instance.get(
        `${window.WS_URL}/admin/company/logo?company_name=${companyName}`
      ),
    chats: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/operator/chats?access_click2chat=${access_click2chat}`
      ),
    chatsByNameOperators: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/operators/chats/by-name?access_click2chat=${access_click2chat}`,
        { filter }
      ),

    chat: (access_click2chat, chat_id) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/chat/${chat_id}?access_click2chat=${access_click2chat}`
      ),
    chatHistory: (access_click2chat, chat_id) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/chat-history/${chat_id}?access_click2chat=${access_click2chat}`
      ),
    file: (access_click2chat, file_id, media_key) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).files_url
        }/file/${file_id}?validation=user&access_click2chat=${access_click2chat}&media_key=${media_key}`
      ),

    filesCloudList: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/files/files-cloud-list?access_click2chat=${access_click2chat}`
      ),

    operatorsList: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/operators/list?access_click2chat=${access_click2chat}`
      ),

    vmsStatus: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/status/list?access_click2chat=${access_click2chat}`
      ),
    operatorsStatus: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/operators/list?access_click2chat=${access_click2chat}`
      ),

    scheduleOperatorSupervision: (access_click2chat, operatorId) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/operator/${operatorId}/schedule?access_click2chat=${access_click2chat}`
      ),
    clockPunchOperatorsSupervision: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/operators/clock-punch?access_click2chat=${access_click2chat}&dates=${filter}`
      ),
    serviceTicketsSupervision: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/service-tickets?access_click2chat=${access_click2chat}&dates=${filter}`
      ),
    waitingInLineLogsSupervision: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/waiting-in-line-log?access_click2chat=${access_click2chat}&dates=${filter}`
      ),
    apiCountUsageSupervision: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/api-count-usage?access_click2chat=${access_click2chat}&dates=${filter}`
      ),
    messagesConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/messages/consolidation?access_click2chat=${access_click2chat}&filter=${time}`
      ),
    closedReasonsConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/closed-reasons/consolidation?access_click2chat=${access_click2chat}&filter=${time}`
      ),
    firstMessageConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/creation/consolidation?access_click2chat=${access_click2chat}&filter=${time}`
      ),
    returnsConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/returns/consolidation?access_click2chat=${access_click2chat}&filter=${time}`
      ),
    holdLineConsolidation: (access_click2chat, time) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/customer/hold-line/?access_click2chat=${access_click2chat}&filter=${time}`
      ),
    closeReasonsList: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/close-reasons?access_click2chat=${access_click2chat}`
      ),
    currentStatusChats: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/current-status?access_click2chat=${access_click2chat}`
      ),

    chatsByName: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/by-name?access_click2chat=${access_click2chat}`,
        { filter }
      ),
    chatsByUser: (access_click2chat, filter, starred = false) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats?access_click2chat=${access_click2chat}`,
        { filter, starred }
      ),
    chatsByCloseXOperator: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/closed-x-operator?access_click2chat=${access_click2chat}`,
        { filter }
      ),
    chatsByTeams: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/teams?access_click2chat=${access_click2chat}`,
        { filter }
      ),
    chatsInLines: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/in-lines?access_click2chat=${access_click2chat}`,
        { filter }
      ),
    chatsBySupervisor: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/supervisor?access_click2chat=${access_click2chat}`,
        {}
      ),
    chatsOpenByFilter: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/open?access_click2chat=${access_click2chat}`,
        { filter }
      ),

    chatSupervision: (access_click2chat, _chat_id) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/chat/${_chat_id}?access_click2chat=${access_click2chat}`
      ),
    nextOperatorListSupervision: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/operators/list?access_click2chat=${access_click2chat}`
      ),
    operatorsListSupervision: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/operators/list?access_click2chat=${access_click2chat}`
      ),

    supervisorsListSupervision: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/list-supervisors?access_click2chat=${access_click2chat}`
      ),

    scheduleOperator: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/operator/schedule?access_click2chat=${access_click2chat}`
      ),
    scheduleChat: (access_click2chat, phone_number) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/chats/schedule?access_click2chat=${access_click2chat}&phone_number=${phone_number}`
      ),

    fileTypes: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/check/file-types?access_click2chat=${access_click2chat}`
      ),

    teams: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/teams?access_click2chat=${access_click2chat}`
      ),
    tags: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/tags?access_click2chat=${access_click2chat}`
      ),
    pausesList: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/operators/pauses/list?access_click2chat=${access_click2chat}`
      ),
    lastCampaignMassive: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/massive/last-campaign?access_click2chat=${access_click2chat}`
      ),
    dayCampaignsMassive: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/massive/day-campaigns?access_click2chat=${access_click2chat}&filter=${filter}`
      ),

    internalCommunicationUsers: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/users?access_click2chat=${access_click2chat}`
      ),
    internalCommunicationUsersOverview: (access_click2chat, operatorId) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/users-overview?access_click2chat=${access_click2chat}&operatorId=${operatorId}`
      ),
    internalChat: (access_click2chat, id) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/chat?access_click2chat=${access_click2chat}&id=${id}`
      ),
    internalChatOverview: (access_click2chat, id, operatorId) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/chat-overview?access_click2chat=${access_click2chat}&id=${id}&operatorId=${operatorId}`
      ),
    internalChats: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/chats?access_click2chat=${access_click2chat}`
      ),
    internalSupervisorCount: (access_click2chat) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/supervisor-count?access_click2chat=${access_click2chat}`
      ),

    serviceTicketsChatsByDate: (access_click2chat, date) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/service-tickets/chats-by-date?access_click2chat=${access_click2chat}&date=${date}`
      ),
    lineDistributionThroughLineAll: (access_click2chat, filter) =>
      instance.get(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/line-distribution-through-line-all?access_click2chat=${access_click2chat}&dates=${filter}`
      ),
  },
  post: {
    login: (access_click2chat) =>
      instance.post(`${window.WS_URL}/security/login`, { access_click2chat }),
    clockPunch: (accessClick2chat, cpStatus) =>
      instance.post(
        `${
          JSON.parse(accessClick2chat).url
        }/operator/clock-punch?access_click2chat=${accessClick2chat}&status=${cpStatus}`,
        {}
      ),
    message: (
      access_click2chat,
      chat_id,
      msg,
      hasMedia,
      file_id,
      team_id,
      team_name
    ) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/chats/interaction/${chat_id}?access_click2chat=${access_click2chat}`,
        { msg, hasMedia, file_id, team_id, team_name }
      ),
    fileResend: (access_click2chat, chat_id, filename, resend, file_id) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/chats/file/interaction/${chat_id}?access_click2chat=${access_click2chat}`,
        {
          filename: filename,
          resend: resend,
          file_id: file_id,
        }
      ),
    file: (access_click2chat, _file) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).files_url
        }/file?validation=user&access_click2chat=${access_click2chat}`,
        { _file }
      ),
    schedule: (
      access_click2chat,
      dateToSend,
      selectedNumber,
      textToSend,
      selectedChatID,
      hasFile,
      files,
      name,
      ddi,
      cpfCnpj,
      email
    ) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/messages/schedule/interaction?access_click2chat=${access_click2chat}`,
        {
          date: dateToSend,
          phone: selectedNumber.trim(),
          msg: textToSend,
          selected_chat_id: selectedChatID || '',
          hasFile: hasFile,
          files: files,
          customerName: name,
          ddi,
          cpf_cnpj: cpfCnpj,
          email,
        }
      ),
    note: (access_click2chat, title, text) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/operator/note?access_click2chat=${access_click2chat}`,
        { title, text }
      ),
    closeChat: (access_click2chat, reason, phone_number) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/chats/chat/close?access_click2chat=${access_click2chat}`,
        { phone_number, reason }
      ),

    clockPunchSupervisionIn: (accessClick2chat) =>
      instance.post(
        `${
          JSON.parse(accessClick2chat).url
        }/supervision/clock-punch/in?access_click2chat=${accessClick2chat}`,
        {}
      ),
    clockPunchSupervisionOut: (accessClick2chat) =>
      instance.post(
        `${
          JSON.parse(accessClick2chat).url
        }/supervision/clock-punch/out?access_click2chat=${accessClick2chat}`,
        {}
      ),
    reason: (
      access_click2chat,
      reason,
      evaluation,
      color,
      endingMessage,
      internalCode,
      teams
    ) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/close-reason?access_click2chat=${access_click2chat}`,
        { reason, evaluation, color, endingMessage, internalCode, teams }
      ),
    massive: (
      access_click2chat,
      massive_list,
      date_to_send,
      hasFile,
      files
      // _teamId,
      // _teamName
    ) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/massive?access_click2chat=${access_click2chat}`,
        {
          massive_list,
          date_to_send,
          files,
          hasFile,
          // teamId: _teamId,
          // teamName: _teamName,
        }
      ),
    massiveV2: (
      access_click2chat,
      massive_lists
      // date_to_send,
      // hasFile,
      // files
      // _teamId,
      // _teamName
    ) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/massive-v2?access_click2chat=${access_click2chat}`,
        {
          massive_lists,
          // date_to_send,
          // files,
          // hasFile,
          // teamId: _teamId,
          // teamName: _teamName,
        }
      ),
    internalMessage: (access_click2chat, id, msg) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/internal-communication/message?access_click2chat=${access_click2chat}`,
        { id, msg }
      ),
    whisper: (access_click2chat, id, msg) =>
      instance.post(
        `${
          JSON.parse(access_click2chat).url
        }/chats/whisper/${id}?access_click2chat=${access_click2chat}`,
        { id, msg }
      ),
  },
  put: {
    star: (access_click2chat, star, phone_number) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/chats/star/${phone_number}?access_click2chat=${access_click2chat}`,
        {
          starred: star,
        }
      ),
    contactName: (
      access_click2chat,
      contact_name,
      cpf_cnpj,
      email,
      phone_number
    ) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/customer/name?access_click2chat=${access_click2chat}`,
        {
          contact_name,
          phone_number,
          cpf_cnpj,
          email,
        }
      ),
    note: (access_click2chat, title, text, id) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/operator/note?access_click2chat=${access_click2chat}`,
        { title, text, id }
      ),
    nextOperator: (access_click2chat, operator_id, phone_number) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/chats/operators/next?access_click2chat=${access_click2chat}`,
        { operator_id, phone_number }
      ),
    nextSupervisor: (access_click2chat, supervisor_id, phone_number) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/chats/operators/next-supervisor?access_click2chat=${access_click2chat}`,
        { supervisor_id, phone_number }
      ),
    pauseOperator: (access_click2chat, statusPause, pauseId) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/operators/pause?access_click2chat=${access_click2chat}`,
        { statusPause, pauseId }
      ),

    updatePassword: (access_click2chat, new_password) =>
      instance.put(
        `${window.WS_URL}/security/password/update?access_click2chat=${access_click2chat}`,
        { new_password }
      ),

    pullFromLine: (access_click2chat, operator_id, username) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/operator/pull?access_click2chat=${access_click2chat}`,
        { operator_id, username }
      ),
    putInLine: (access_click2chat, operator_id, username) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/operator/put?access_click2chat=${access_click2chat}`,
        { operator_id, username }
      ),
    reason: (
      access_click2chat,
      reason,
      id,
      active,
      evaluation,
      color,
      endingMessage,
      internalCode,
      teams
    ) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/close-reason?access_click2chat=${access_click2chat}`,
        {
          reason,
          id,
          active,
          evaluation,
          color,
          endingMessage,
          internalCode,
          teams,
        }
      ),

    nextOperatorSupervision: (
      access_click2chat,
      operator_id,
      old_operator,
      phone_number
    ) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/operators/next?access_click2chat=${access_click2chat}`,
        { operator_id, old_operator, phone_number }
      ),
    nextSupervisorSupervision: (
      access_click2chat,
      supervisor_id,
      old_operator,
      phone_number
    ) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/operators/next-supervisor?access_click2chat=${access_click2chat}`,
        { supervisor_id, old_operator, phone_number }
      ),
    schedule: (access_click2chat, reminder_id) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/messages/schedule/resend/${reminder_id}?access_click2chat=${access_click2chat}`,
        {}
      ),

    blockOperator: (access_click2chat, operatorId) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/operator/block?access_click2chat=${access_click2chat}`,
        { operatorId }
      ),
    logoutOperator: (access_click2chat, operatorId) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/operator/logout?access_click2chat=${access_click2chat}`,
        { operatorId }
      ),
    unblockOperator: (access_click2chat, operatorId) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/operator/unblock?access_click2chat=${access_click2chat}`,
        { operatorId }
      ),

    teamUser: (access_click2chat, operatorId, teamId, teamName) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/team/update-team-operator?access_click2chat=${access_click2chat}`,
        { operatorId, teamId, teamName }
      ),
    teamVM: (access_click2chat, vmId, teamId, teamName, direction) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/team/update-team-vm?access_click2chat=${access_click2chat}`,
        { vmId, teamId, teamName, direction }
      ),
    tagVM: (access_click2chat, vmId, tagId, tagName) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/tag/vm?access_click2chat=${access_click2chat}`,
        { vmId, tagId, tagName }
      ),

    transferAllChatsToLine: (access_click2chat, operatorId) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/supervision/chats/transfer/line?access_click2chat=${access_click2chat}`,
        { operatorId }
      ),

    blockChat: (access_click2chat, phone_number, status) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/chats/block-chat?access_click2chat=${access_click2chat}`,
        { phone_number, status }
      ),

    changeVMChat: ({ access_click2chat, phone_number, vm }) =>
      instance.put(
        `${
          JSON.parse(access_click2chat).url
        }/chats/change-vm?access_click2chat=${access_click2chat}`,
        { phone_number, vm }
      ),
  },
  delete: {
    note: (access_click2chat, id) =>
      instance.delete(
        `${
          JSON.parse(access_click2chat).url
        }/operator/note?access_click2chat=${access_click2chat}&id=${id}`
      ),

    operator: (access_click2chat, operator_id) =>
      instance.delete(
        `${window.WS_URL}/operator/delete?access_click2chat=${access_click2chat}`,
        { operator_id }
      ),
    schedule: (access_click2chat, reminder_id) =>
      instance.delete(
        `${
          JSON.parse(access_click2chat).url
        }/messages/schedule/${reminder_id}?access_click2chat=${access_click2chat}`,
        {}
      ),
  },
};

export default api;
